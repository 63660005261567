export const Header = (props) => {

  let data = [];

  if (props != null) {
    if (props.data != null) {
      // console.log(props.data[0]);
      data = props.data;
    }
   // data = props.data[0].Name;
  }

  return (
    <header id='header'>
      <div className='intro' style={data.HeroImage ? {backgroundImage: 'url("/img/background.jpg")'} : {backgroundImage: 'url("img/background.jpg")'}}>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12 intro-text'>
                <h1>
                  {data ? "Focused. Sophisticated. Agile." : 'Loading'}
                  <span></span>
                </h1>
                <p>{data ? "A boutique litigation firm dedicated to solving your problems." : 'Loading'}</p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
} 