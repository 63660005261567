export const Features = (props) => {

  let data = [];

  if (props != null) {
    if (props.data != null) {
      // console.log(props.data[0]);
      data = props.data;
    }
   // data = props.data[0].Name;
  }

  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>What We Do</h2>
        </div>
        <div className='row feature-row'>
          {/* {data
            ? data.map((d, i) => (
              <div key={`${d.title}-${i}`} className='col-xs-12 col-md-3'>
                  {console.log(d)}
                  {' '}
                  <h3>{d.Title}</h3>
                  <p>{d.Text}</p>
                </div>
              ))
            : 'Loading...'} */}
            <div className='col-xs-12 col-md-3'>
              <h3>Commercial Litigation</h3>
              <p>Our sophisticated litigation team brings over a decade of experience at large international firms and Fortune 100 legal departments to successfully represent clients before, during, and after commercial disputes of all sizes.</p>
            </div>
            <div className='col-xs-12 col-md-3'>
              <h3>Outside General Counsel</h3>
              <p>With experience across various industries and practice areas, we provide tailored, business-savvy advice to help clients achieve their strategic goals.</p>
            </div>
            <div className='col-xs-12 col-md-3'>
              <h3>Content Liability and E-Commerce</h3>
              <p>We focus on providing ecommerce businesses with advice and representation, and our litigation team has developed particular expertise applying Section 230 to defend internet business from claims involving user-generated content.</p>
            </div>
        </div>
      </div>
    </div>
  )
}
