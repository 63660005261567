import {useParams} from "react-router-dom";
export const TeamMember = (props) => {

    let data = [];
    let {name} = useParams();
    let dataPerm = [];

    if (props != null) {
      if (props.data != null) {
        // console.log(props.data[0]);
        data = props.data;
        data = data.find(n=> n.Slug.toLowerCase() === name.toLowerCase())
      }
     // data = props.data[0].Name;
    }

    return (
    
        <section id='team-member-page'>
            <header id="header">
                <div class="intro" style={{backgroundImage: "url('https://res.cloudinary.com/dujwhna8n/image/upload/v1637100339/pawel_chu_U_Lh0i2tx_BCY_unsplash_aff3523b0c.jpg')"}}>
                    <div class="overlay">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-8 col-md-offset-2 intro-text">
                                    <h1>Our Team</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* {console.log(data.find(n => n.Slug.toLowerCase() === name.toLowerCase()))} */}
            <div className="container main-contain">
                <div className="row">
                    <div className="col-md-4">
                        <img src='/img/tim2.jpg' className='team-img' />
                    </div>
                    <div className="col-md-8">
                        <div className="name-section">
                            <h1>Timothy (Tim) L. Moore</h1>
                            <p>Owner</p>
                        </div>
                        <div className="contact-section">
                            <h2>Contact</h2>
                            <p className="phone">415-697-1105</p>
                            <p className="email">tim@tlmoorelawoffices.com</p>
                            <p className="location">San Francisco, CA</p>
                        </div>
                        <div className="description-section">
                            <p>Tim is a business attorney whose clients have spanned Fortune 100 financial institutions to small and medium size businesses.  He spent over a decade representing clients in commercial litigation, defending consumer financial services claims, and advising businesses and sophisticated individuals navigating legal risk. Tim has worked both in-house at Morgan Stanley, and at the large multinational law firm Reed Smith LLP, and now practices as a nimble solo practitioner at the Law Offices of Timothy L. Moore.  Tim focuses on ecommerce businesses in particular and is expert in applying Section 230 protection against user-created liability.  
<br /><br />In addition, Tim has represented individuals and businesses in ERISA healthcare litigation, wealth management disputes, FLSA claims, and personal injury / wrongful death claims involving highly-regulated industries like railroads, trucking, and mining. Tim historically devotes significant time to pro bono efforts—including co-chairing a jury trial resulting in a verdict and punitive damages for his client.
<br /><br />Tim graduated from the Georgetown University Law Center and the University of Pittsburgh.
</p>
                        </div>
                        <div className="row member-details">
                            <div className="col-md-6">
                                <h3>Areas of Expertise</h3>
                                <p>Section 230 / content liability</p>
                                <p>Ecommerce business counseling</p>
                                <p>Commercial Litigation</p>
                                <p>Business</p>
                            </div>
                            <div className="col-md-6">
                                <h3>Prior Associations</h3>
                                <p>Morgan Stanley</p>
                                <p>Reed Smith LLP</p>
                                <p>FisherBroyles, LLP</p>
                            </div>
                        </div>
                        <div className="row member-details">
                            <div className="col-md-6">
                                <h3>Education</h3>
                                <p>Georgetown University Law Center, 2009</p>
                                <p>University of Pittsburgh, 2004</p>
                            </div>
                            <div className="col-md-6">
                                <h3>Bar Admission</h3>
                                <p>California, Pennsylvania, West Virginia</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
  