export const About = (props) => {

  let data = [];

  if (props != null) {
    if (props.data != null) {
      // console.log(props.data[0]);
      data = props.data;
    }
   // data = props.data[0].Name;
  }

  // let html = "";

  // for(var elem in data.List1) {
  //   html += "<li>" + elem + "</li>";
  // }

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Who We Are</h2>
              <p>Tim started the firm with a straightforward idea: sophisticated small business clients do not need to pay big firm prices for sophisticated lawyers. We provide focused, seasoned representation through state-of-the-art technology and the agility of a simplified firm structure at a price that lets you do what you do best—taking care of business. We specialize in working with ecommerce SMBs but provide big firm and Fortune 100 quality without charging for the overhead that goes with it to all of our valued clients.</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  {/* <ul dangerouslySetInnerHTML={{__html: html}}> */}
                  <ul>
                    <li>Section 230 / content liability.</li>
                    <li>Big firm / Fortune 100 training and experience.</li>
                    <li>Business-minded practical advice.</li>
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    <li>Responsive.</li>
                    <li>Detail-oriented.</li>
                    <li>Always in your corner.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
