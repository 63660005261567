export const Testimonials = (props) => {

  let data = [];

  if (props != null) {
    if (props.data != null) {
      // console.log(props.data[0]);
      data = props.data;
    }
   // data = props.data[0].Name;
  }

  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>What our clients say</h2>
        </div>
        <div className='row'>
         {data
            ? data.map((d, i) => (
                <div key={`${d.Testimonial.Name}-${i}`} className='col-md-4'>
                  <div className='testimonial'>
                    <div className='testimonial-image'>
                      {' '}
                      <img src={d.Testimonial.Image[0].url} alt='' />{' '}
                    </div>
                    <div className='testimonial-content'>
                      <p>"{d.Testimonial.Quote}"</p>
                      <div className='testimonial-meta'> - {d.Testimonial.Name} </div>
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
