import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Testimonials } from "./components/testimonials";
import { TeamMember } from "./components/TeamMember";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import axios from 'axios';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {

  const [heroPageData, setHeroPageData] = useState(null)
  useEffect(async () => {
    const fetchData = async () => {
        axios.get('https://tombacksite-backend.herokuapp.com/Hero').then(function (r) {
          setHeroPageData(r.data);
        });
    };

   fetchData();
  }, []);

  const [featurePageData, setFeaturePageData] = useState(null)
  useEffect(async () => {
    const fetchData = async () => {
        axios.get('https://tombacksite-backend.herokuapp.com/What-We-Dos').then(function (r) {
          setFeaturePageData(r.data);
        });
    };

   fetchData();
  }, []);

  const [aboutPageData, setAboutPageData] = useState(null)
  useEffect(async () => {
    const fetchData = async () => {
        axios.get('https://tombacksite-backend.herokuapp.com/About-Us').then(function (r) {
          setAboutPageData(r.data);
        });
    };

   fetchData();
  }, []);

  const [contactPageData, setContactPageData] = useState(null)
  useEffect(async () => {
    const fetchData = async () => {
        axios.get('https://tombacksite-backend.herokuapp.com/contact-info').then(function (r) {
          setContactPageData(r.data);
        });
    };

   fetchData();
  }, []);

  // const [testimonialPageData, setTestimonialPageData] = useState(null)
  // useEffect(async () => {
  //   const fetchData = async () => {
  //       axios.get('https://tombacksite-backend.herokuapp.com/Testimonials').then(function (r) {
  //         setTestimonialPageData(r.data);
  //       });
  //   };

  //   fetchData();
  // }, []);

  const [teamMemberData, setTeamMemberData] = useState(null)
  useEffect(async () => {
    const fetchData = async () => {
        axios.get('https://tombacksite-backend.herokuapp.com/team-members').then(function (r) {
          setTeamMemberData(r.data);
        });
    };

   fetchData();
  }, []);

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  function HomePage() {
    return (
      <div>
        <Header data={heroPageData} />
        <Features data={featurePageData} />
        <About data={aboutPageData} />
        {/* <Testimonials data={testimonialPageData} /> */}
        <Team data={teamMemberData} />
      </div>
    );
  }

  return (
    <div>
      <Navigation data={landingPageData.Navigation}/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Tim-Moore" element={<TeamMember />}/>
        </Routes>
      </BrowserRouter>
      <Contact data={landingPageData.Contact} />
    </div>
  );
}


export default App;

