export const Team = (props) => {

  let data = [];

  if (props != null) {
    if (props.data != null) {
      // console.log(props.data[0]);
      data = props.data;
    }
   // data = props.data[0].Name;
  }

  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
          <p>
            Our team of professionals is at your service. 
          </p>
        </div>
        <div id='row' class="team-center">
          <div  className='col-md-3 col-sm-6 team'>
            <a href='/Tim-Moore' className='thumbnail'>
              <img src='/img/tim2.jpg' alt='Tim Moore' className='team-img' />
              <div className='caption'>
                <h4>Timothy (Tim) L. Moore</h4>
                <p>Owner</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
